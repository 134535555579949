import React from "react";
import { AuthProvider } from "../../../components/Auth/auth";
import DashboardLayout from "../../../components/DashboardLayout";
import AccountAuctionSettings from "../../../components/PageComponents/Dashboard/Account/AuctionSettings/AccountAuctionSettings";

const AuctionSettings = () => (
  <AuthProvider>
    <DashboardLayout title="Bidddy - Your Account | Notification Settings">
      <AccountAuctionSettings />
    </DashboardLayout>
  </AuthProvider>
);

export default AuctionSettings;
