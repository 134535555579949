import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CurrencyInput from "react-currency-input-field";
import { navigate } from "gatsby";
import {
  getAuctionSettings,
  AccountSettingsRequestParams,
  getUserInfo,
  AuctionSettings,
  AuctionSettingsPatchParams,
  updateAuctionSettings,
} from "../../../../../store/user/actions";
import { useAppDispatch } from "../../../../../store";
import {
  selectAuctionSettings,
  selectUserInfo,
} from "../../../../../store/user/selectors";
import { User } from "../../../../../types/User";
import styles from "./AccountAuctionSettings.module.scss";
import BasicTable from "../../../../Table/BasicTable";
import BasicTableRow from "../../../../Table/BasicTableRow";
import BasicTableHeader from "../../../../Table/BasicTableHeader";
import BasicTableBody from "../../../../Table/BasicTableBody";
import ForwardArrow from "../../Auctions/AuctionCreation/internal/ForwardArrow";
import {
  getCurrencyLocaleValue,
  getCurrencySymbol,
  sendToast,
} from "../../../../../utils/helpers";
import { CurrencyOptions } from "../../../../../enums/CurrencyTypes";

const AccountAuctionSettings = () => {
  const dispatch = useAppDispatch();
  const userInfo = useSelector((state) => selectUserInfo(state)) as User;
  const auctionSettings = useSelector((state) =>
    selectAuctionSettings(state)
  ) as AuctionSettings;
  const [displayBidIncrements, setDisplayBidIncrements] = useState(false);
  const [displayBidPriceModal, setDisplayBidPriceModal] = useState(false);
  const [displayCurrencyOptions, setDisplayCurrencyOptions] = useState(false);
  const [displayDefaultDays, setDisplayDefaultDays] = useState(false);

  useEffect(() => {
    if (userInfo) {
      const params = {
        store_id: userInfo.data.last_store_id,
      } as AccountSettingsRequestParams;
      dispatch(getAuctionSettings(params));
    }
  }, [userInfo]);

  useEffect(() => {
    if (!userInfo) {
      dispatch(getUserInfo());
    }
  }, []);

  const navigateBack = () => {
    navigate("/dashboard/account/");
  };

  const updateCurrency = (currency: string) => {
    if (currency) {
      const updateParams = {
        store_id: userInfo.data.last_store_id,
        auction_setting: {
          ...auctionSettings,
          currency,
        },
      } as AuctionSettingsPatchParams;
      dispatch(updateAuctionSettings(updateParams));
    }
  };

  const updateStartingBid = (startingPrice: any) => {
    if (startingPrice && startingPrice !== "0") {
      const updateParams = {
        store_id: userInfo.data.last_store_id,
        auction_setting: {
          ...auctionSettings,
          price: startingPrice,
        },
      } as AuctionSettingsPatchParams;
      dispatch(updateAuctionSettings(updateParams));
    } else {
      sendToast("Please set a starting price greater than zero", {
        type: "warning",
      });
    }
  };

  const updateBidIncrements = (increments: any) => {
    if (increments && increments !== "0") {
      const updateParams = {
        store_id: userInfo.data.last_store_id,
        auction_setting: {
          ...auctionSettings,
          increment: increments,
        },
      } as AuctionSettingsPatchParams;
      dispatch(updateAuctionSettings(updateParams));
    } else {
      sendToast("Please set the increment greater than zero", {
        type: "warning",
      });
    }
  };

  const updateDuration = (duration: string) => {
    if (duration) {
      const updateParams = {
        store_id: userInfo.data.last_store_id,
        auction_setting: {
          ...auctionSettings,
          duration_days: parseInt(duration, 10),
        },
      } as AuctionSettingsPatchParams;
      dispatch(updateAuctionSettings(updateParams));
    }
  };

  return (
    <>
      <div className={styles.auctionSettingsContainer}>
        <div>
          <button
            type="button"
            className={styles.backButton}
            onClick={navigateBack}
          >
            Back
          </button>
          <h2>Auction Settings</h2>
        </div>
        <p>You can modify your auction settings here.</p>
        <BasicTable>
          <BasicTableHeader>
            <BasicTableRow>
              <td colSpan={2}>Auction Settings</td>
            </BasicTableRow>
          </BasicTableHeader>
          <BasicTableBody>
            {auctionSettings && (
              <>
                <BasicTableRow
                  onClick={() =>
                    setDisplayCurrencyOptions(!displayCurrencyOptions)
                  }
                >
                  <td>Currency</td>
                  <td className="text-right relative">
                    {auctionSettings.currency}
                    <ForwardArrow onClick={() => {}} />
                    {displayCurrencyOptions && (
                      <div className={styles.selectCurrencyOptions}>
                        <div className={styles.currencyOptionsContainer}>
                          {CurrencyOptions.map((option) => (
                            <button
                              type="button"
                              key={option}
                              onClick={() => updateCurrency(option)}
                            >
                              {option}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </td>
                </BasicTableRow>
                <BasicTableRow onClick={() => setDisplayBidIncrements(true)}>
                  <td>Price Increment</td>
                  <td className="text-right">
                    {getCurrencyLocaleValue(
                      auctionSettings.currency,
                      auctionSettings.increment
                    )}
                    <ForwardArrow onClick={() => {}} />
                  </td>
                </BasicTableRow>
                <BasicTableRow onClick={() => setDisplayBidPriceModal(true)}>
                  <td>Starting Price</td>
                  <td className="text-right">
                    {getCurrencyLocaleValue(
                      auctionSettings.currency,
                      auctionSettings.price
                    )}
                    <ForwardArrow onClick={() => {}} />
                  </td>
                </BasicTableRow>
                <BasicTableRow onClick={() => setDisplayDefaultDays(true)}>
                  <td>Auction Duration (days)</td>
                  <td className="text-right">
                    {auctionSettings.duration_days}{" "}
                    <ForwardArrow onClick={() => {}} />
                  </td>
                </BasicTableRow>
              </>
            )}
          </BasicTableBody>
        </BasicTable>
      </div>
      {displayBidPriceModal && (
        <div className={styles.bidPriceModal}>
          <div className={styles.bidPriceModalContainer}>
            <h3>Default Starting Bid Price</h3>
            <CurrencyInput
              id="starting-bid"
              name="starting bid"
              prefix={getCurrencySymbol(auctionSettings.currency)}
              allowDecimals={false}
              disableGroupSeparators
              disableAbbreviations
              allowNegativeValue={false}
              placeholder="Enter your starting bid price"
              defaultValue={auctionSettings.price}
              decimalsLimit={0}
              onValueChange={(value) => updateStartingBid(value)}
            />
            <div className="flex flex-row mt-4 justify-center">
              <button
                type="button"
                onClick={() => setDisplayBidPriceModal(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      {displayBidIncrements && (
        <div className={styles.bidPriceModal}>
          <div className={styles.bidPriceModalContainer}>
            <h3>Default Bid Increments</h3>
            <CurrencyInput
              id="increment-bid"
              name="increment bid"
              prefix={getCurrencySymbol(auctionSettings.currency)}
              allowDecimals={false}
              disableGroupSeparators
              disableAbbreviations
              allowNegativeValue={false}
              placeholder="Enter your bid increment"
              defaultValue={auctionSettings.increment}
              decimalsLimit={0}
              onValueChange={(value) => updateBidIncrements(value)}
            />
            <div className="flex flex-row mt-4 justify-center">
              <button
                type="button"
                onClick={() => setDisplayBidIncrements(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      {displayDefaultDays && (
        <div className={styles.bidPriceModal}>
          <div className={styles.bidPriceModalContainer}>
            <h3>Default Auction Duration (Days)</h3>
            <CurrencyInput
              id="default-days"
              name="default days"
              prefix=""
              allowDecimals={false}
              disableGroupSeparators
              disableAbbreviations
              allowNegativeValue={false}
              placeholder="Enter your default duration (days)"
              defaultValue={auctionSettings.duration_days}
              decimalsLimit={0}
              onValueChange={(value) => updateDuration(value)}
            />
            <div className="flex flex-row mt-4 justify-center">
              <button
                type="button"
                onClick={() => setDisplayDefaultDays(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountAuctionSettings;
